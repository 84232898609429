import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFinderApi } from './useFinderApi';
//import { searchSpaces } from './api'; 
import {Card} from './ui/Card'; 
import { Drawer } from 'vaul';
//import { makeReservation } from './api';
import { AlertDialog } from './ui/AlertDialog';
import { FaChevronRight  } from 'react-icons/fa';

const SearchResults = () => {
  const {searchSpaces, makeReservation} = useFinderApi();

  const { id } = useParams(); // Get the item ID from the URL
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedResult, setExpandedResult] = useState(null); // For expanded card
  const [spaceToReserve, setSpaceToReserve] = useState(null);
  const [lookupDescriptor, setLookupDescriptor] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [showDialog, setShowDialog] = useState(false); 
  const [error, setError] = useState(''); 
  const [reserving, setReserving] = useState(false); 

  const [checklist, setChecklist] = useState([]);
  const [intakeSlot, setIntakeSlot] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch search results based on the item ID
    searchSpaces(id)
      .then((results) => {
        if (results.matches) {
          setSearchResults(results.matches);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching search results:', error);
        setLoading(false);
      });
  }, [id, searchSpaces]);

  const handleResultClick = (result) => {
    setExpandedResult(result); // Set the selected result to be expanded
  };

  const handleCollapse = (e) => {
    e.stopPropagation(); // Stop the click event from propagating to the parent card
    setExpandedResult(null); // Collapse the expanded card
  };

  const handleSpaceClick = (space) => {
    // Handle the logic when a sub-item card is clicked
    console.log("Space clicked:", space);

    // reset the reservation fields
    setIntakeSlot(null);
    setLookupDescriptor("");
    
    // check if there is an eligibility checklist
    const checklistItems = [];
    if (space?.eligibility_checklist) {
      space.eligibility_checklist
        .split('\n')
        .forEach((line, index) => {
          const trimmedLine = line.trim();
          if (trimmedLine) {
            checklistItems.push({id: index, label: trimmedLine, checked: false});
          }
        })
    }
    setChecklist(checklistItems);

    setSpaceToReserve(space);
  };

  const closeDrawer = () => {
    setSpaceToReserve(null);
  }

  const handleLookupDescriptorChange = (e) => {
    setLookupDescriptor(e.target.value);
  };

  const handleAdditionalDetailsChange = (e) => {
    setAdditionalDetails(e.target.value);
  }

  const handleIntakeSlotChange = (e) => {
    setIntakeSlot(e.target.value);
  };

  const handleSubmit = async (shelterId, spaceTypeId, lookupDescriptor, additionalDetails, hasAvailableIntakeSlots) => {

    if (hasAvailableIntakeSlots && !intakeSlot) {
      setError("Please select an intake option");
      return;
    }

    if ((checklist.length > 0) && (!checklist.every(item => item.checked === true))) {
      setError("Your client does not meet all of the eligibility criteria for this space");
      return;
    }

    if (lookupDescriptor.trim().length === 0) {
      setError("Please enter the required information");
      return;
    }
    

    setReserving(true);

    const reservationData = {
      shelter_id: shelterId,
      space_type_id: spaceTypeId,
      intake_slot_id: intakeSlot,
      lookup_descriptor: lookupDescriptor,
      additional_details: additionalDetails,
    };


    try {
      await makeReservation(id, reservationData);
      setSpaceToReserve(null);
      setShowDialog(true);
    } catch (error) {
      setError(`Failed to create reservation: ${error.message}`); // Display error message
    } finally {
      setReserving(false); 
    } 
  };

  
  const handleChecklistChange = (id) => {
    setChecklist((prevChecklist) =>
      prevChecklist.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };
  

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate('/'); // Navigate to the main route after closing the dialog
  };

  const handleBackClick = () => {
    navigate(`/needs/${id}/update`); // Navigate back to the need page
  };

  const formatTimeRange = (startTime, endTime) => {
    const formatTime = (time) => {
      const [hour, minute] = time.split(":").map(Number);
      const period = hour >= 12 ? "pm" : "am";
      const formattedMinute = minute.toString().padStart(2, "0");
      return `${hour % 12 || 12}:${formattedMinute} ${period}`;
    };
  
    if (!startTime && !endTime) return "Anytime";
    if (!startTime) return `Until ${formatTime(endTime)}`;
    if (!endTime) return `${formatTime(startTime)} onwards`;
  
    return (
      <>
        <span style={{ whiteSpace: "nowrap" }}>{formatTime(startTime)} -</span>{" "}
        <span style={{ whiteSpace: "nowrap" }}>{formatTime(endTime)}</span>
      </>
    );
  }

  return (
    <Drawer.Root shouldScaleBackground open={spaceToReserve !== null} onClose={closeDrawer}>
      
    <div className="p-4">
      {/* Back Button */}
      <button onClick={handleBackClick} className="text-blue-600">
        ← Back to search
      </button>
    </div>
    <div className="p-8">
      
      <h2 className="text-lg font-semibold text-gray-600 mb-8">These shelters may have spaces that match your client's needs:</h2>
      {loading ? (
        <p>Loading search results...</p>
      ) : (
        <div className="grid grid-cols-1 ">
          {searchResults.map((result) => (
            <Card
              key={result.shelter?.shelter_id}
              className={`cursor-pointer hover:shadow-lg border ${
                expandedResult?.shelter.shelter_id === result.shelter.shelter_id ? 'fixed inset-0 bg-white p-6 overflow-y-auto' : 'mb-6'
              }`}
              onClick={() => handleResultClick(result)}
              style={expandedResult?.shelter.shelter_id === result.shelter.shelter_id ? { maxHeight: '100vh' } : {}}
            >
              {expandedResult?.shelter.shelter_id === result.shelter.shelter_id && (
                <div>
                  <button
                    className="mt-4 text-blue-600"
                    onClick={handleCollapse}
                  >
                    ← Back to shelter list
                  </button>
                </div>
              )}
              
              <div className="p-4">
                <h2 className="font-semibold text-lg">{result.shelter?.name}</h2>

                <div className="mt-2 text-sm text-gray-800">
                  <p>{result.shelter?.address?.street1}</p>
                  {result.shelter?.address?.street2 && (<p>{result.shelter?.address?.street2}</p>)}
                  <p>{result.shelter?.address?.city}, {result.shelter?.address?.state} {result.shelter?.address?.postal_code}</p>
                  <p>{result.shelter?.phone}</p>
                </div>

                {/* Summary content */}
                {expandedResult?.shelter.shelter_id !== result.shelter.shelter_id && (
                
                  <div className="mt-6">
                    {result?.shelter.short_description && (
                      <p className="text-sm text-gray-600">
                        {result.shelter?.short_description}
                      </p>
                    )}
                    {!result?.shelter.short_description && (
                      <p className="text-sm text-gray-600">
                        {result.shelter?.description?.slice(0, 200)}{result.shelter?.description?.length > 200 ? "..." : ""}
                      </p>
                    )}                    
                    
                    <div className="mt-4">
                      <p className="font-bold mb-2 text-gray-600">Matching spaces:</p>
                      <ul className="list-disc">
                      {result.spaces.map((space) => (
                        <li key={space.space_type_id} className="text-sm text-green-900 mx-4">
                          <strong>{space.name}:</strong> <span className="text-gray-800">{space.available_spaces} spaces</span>
                        </li>
                      ))}
                      </ul>
                    </div>
                    <div className="mt-6 text-right text-blue-800 font-bold"><span>View shelter details <FaChevronRight className="inline-flex"/></span></div>
                  </div>
                  
                )}

                {/* Expanded content */}
                {expandedResult?.shelter.shelter_id === result.shelter.shelter_id && (
                  <div>
                    <div className="mt-6" >
                      <p className="text-base text-gray-700 whitespace-pre-line">{result.shelter?.description}</p>
                      <h3 className="mt-4 font-semibold">Matching spaces:</h3>
                      <div className="grid grid-cols-1 gap-4">
                        {result.spaces.map((space) => (
                          <Card
                            key={space.space_type_id}
                            className="cursor-pointer border my-4 hover:shadow-md border-gray-900 bg-gray-100"
                            onClick={() => handleSpaceClick(space)}
                          >
                            <div className="p-4">
                              <h4 className="font-bold text-md text-green-900 mb-2">{space.name}</h4>
                              <p className="text-sm text-gray-600">
                                {space.short_description && (
                                  <p className="text-sm text-gray-600">
                                    {space.short_description}
                                  </p>
                                )}
                                {!space.short_description && (
                                  <p className="text-sm text-gray-600">
                                    {space.description?.slice(0, 200)}{space.description?.length > 200 ? "..." : ""}
                                  </p>
                                )} 
                              </p>
                              <p className="text-sm text-gray-800 mt-2">
                                <strong>Available spaces:</strong> {space.available_spaces}
                              </p>
                            </div>
                            <div className="mt-6 text-right text-blue-800 font-bold"><span>Reserve this space <FaChevronRight className="inline-flex"/></span></div>
                          </Card>
                          
                        ))}
                      </div>
                      
                    </div>
                  </div>
                )}

              </div>
            </Card>
          ))}
        </div>
      )}
    </div>


    <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40" />
        <Drawer.Content className="bg-white flex flex-col fixed bottom-0 left-0 right-0 max-h-[96%] rounded-t-[10px]">
          
          <div className="max-w-md w-full mx-auto flex flex-col overflow-auto p-4 rounded-t-[10px]">
            <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-8" />
          
            <Drawer.Title className="text-xl font-bold mb-2">
              Reserve a space
            </Drawer.Title>
            
            <div className="mb-6">
              <p>
                Shelter: {expandedResult?.shelter.name}
              </p>
              <p>
                Space: {spaceToReserve?.name}
              </p>
            </div>


            <p className="text-lg text-gray-700 mb-6">{spaceToReserve?.description}</p>

            {Array.isArray(spaceToReserve?.available_intake_slots) && (
              <div className="mb-6">
                <h3 className="font-bold text-gray-700 mb-4">Please choose an intake option:</h3>
                <ul>
                  {spaceToReserve.available_intake_slots.map((item) => (
                    <li key={item.id} className="flex items-start mb-2">
                      
                      <input
                        type="radio"
                        name="intakeSlot"
                        id={`intakeslot-radio-${item.intake_slot_id}`}
                        value={item.intake_slot_id}
                        checked={intakeSlot === item.intake_slot_id}
                        onChange={handleIntakeSlotChange}
                        className="mr-2 mt-1"
                      />
                      
                      
                      <label htmlFor={`intakeslot-radio-${item.intake_slot_id}`} className="text-sm text-gray-800">
                        <div className="font-bold text-green-900">{item.name}</div>
                        <div className="font-semibold text-xs">{new Date(`${item.date}T00:00:00Z`).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', timeZone: 'UTC' })}</div>
                        <div className="font-semibold text-xs">
                        {formatTimeRange(item.intake_start_time, item.intake_end_time)}
                        </div>
                        {
                          (item.description) &&
                          <div className="text-xs">{item.description}</div>
                        }
                        
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}



            {(checklist.length > 0) && (
              <div className="mb-6">
                <h3 className="font-bold text-gray-700 mb-4">Please check each of the following items to confirm eligibility for this space:</h3>
                <ul>
                  {checklist.map((item) => (
                    <li key={item.id} className="flex items-start mb-2">
                      <input
                        type="checkbox"
                        id={`checkbox-${item.id}`}
                        checked={item.checked}
                        onChange={() => handleChecklistChange(item.id)}
                        className="mr-2 mt-1"
                      />
                      <label htmlFor={`checkbox-${item.id}`} className="text-sm text-gray-800">
                        {item.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            
            <div className="mb-6">
        
              <p className="mb-2 font-bold">{expandedResult?.shelter.name} requires the following information to make a reservation:</p>
              <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="lookup-descriptor">
                {expandedResult?.shelter.reservation_lookup_descriptor_format}
              </label>
              <input
                type="text"
                id="lookup-descriptor"
                value={lookupDescriptor}
                onChange={handleLookupDescriptorChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="mb-6">
              <p className="mb-2 font-bold">If you would like to provide additional information about your client, please enter it here:</p>
              <textarea
                id="additional-details"
                rows="4"
                maxLength="1000"
                value={additionalDetails}
                onChange={handleAdditionalDetailsChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="mb-6">
            {error && <p className="text-red-600 text-sm mt-2">{error}</p>}
            </div>

            {/* Buttons */}
            <div className="flex justify-end space-x-4">
              <button
                onClick={closeDrawer}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSubmit(expandedResult.shelter.shelter_id, spaceToReserve.space_type_id, lookupDescriptor, additionalDetails, Array.isArray(spaceToReserve.available_intake_slots))}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                disabled={reserving} // Disable button while reserving
              >
                {reserving ? 'Reserving...' : 'Reserve this space'} 
              </button>
            </div>


          </div>
        </Drawer.Content>
      </Drawer.Portal>
      <AlertDialog
        isOpen={showDialog}
        title="Reservation Created"
        message="A reservation has been created for your client"
        onClose={handleCloseDialog}
      /> 
    </Drawer.Root>
  );
};

export default SearchResults;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from './ui/Button';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFinderApi } from './useFinderApi';
//import SearchResults from './SearchResults';
//import { getNeedById, updateNeedForId, searchSpaces } from './api';

const UpdateNeed = () => {
  const {getNeedById, updateNeedForId, searchSpaces} = useFinderApi();

  const [referenceIdentifier, setReferenceIdentifier] = useState("");
  const [spaceTypeCategories, setSpaceTypeCategories] = useState([]);
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [isCaPc290, setIsCaPc290] = useState(null);
  const [isArsonHistory, setIsArsonHistory] = useState(null);
  const [numPets, setNumPets] = useState('');
  const [currentRegion, setCurrentRegion] = useState('');
  
  const [ageError, setAgeError] = useState('');
  const [status, setStatus] = useState(''); // For status information

  const [needId, setNeedId] = useState(null); 
  const [loading, setLoading] = useState(true); 

  const [searchResultsCount, setSearchResultsCount] = useState(null); 
  const [searchComplete, setSearchComplete] = useState(false); 

  const [formComplete, setFormComplete] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { isNewNeed } = location.state || {};
  const { id } = useParams(); // For editing existing items

  const initialRender = useRef(true);

  useEffect(() => {
    if (id) {
      // get saved need
      
      getNeedById(id).then((need) => {
        setNeedId(need.id);
        setReferenceIdentifier(need.reference_identifier);
        setSpaceTypeCategories((need.space_type_categories == null) ? [] : need.space_type_categories);
        setAge((need.age == null) ? '' : need.age.toString() );
        setGender((need.gender == null) ? '' : need.gender);

        if (need.behavioral_history == null) {
          setIsCaPc290("");
          setIsArsonHistory("");
        }
        else {
          setIsCaPc290(need.behavioral_history.includes("ca_pc_290") ? "Yes" : "No");
          setIsArsonHistory(need.behavioral_history.includes("arson") ? "Yes" : "No");
        }
        
        setNumPets((need.num_pets == null) ? '' : need.num_pets.toString() );
        setCurrentRegion((need.current_region == null) ? '' : need.current_region);

        setLoading(false); 

      }).catch((error) => {
        setStatus('Error loading need details: ' + error.message);
      });
      
    } 
  }, [id, isNewNeed, getNeedById]);

  // TODO: Refactor. This is a hack to avoid having an array in the dependency array in the useEffect below. It results in a warning, which I am irresponsibly telling lint to ignore
  const stringifiedSpaceTypeCategories = JSON.stringify(spaceTypeCategories);

  useEffect(() => {
    const getNeedObjectFromFormState = () => {
      let need = {};
      need.reference_identifier = referenceIdentifier.trim();
      need.space_type_categories = (spaceTypeCategories && spaceTypeCategories.length > 0) ? spaceTypeCategories : null;
      need.current_region = currentRegion ? currentRegion : null;
      need.age = age.trim() && !isNaN(Number(age)) ? Number(age) : null;
      need.gender = gender ? gender : null;
      need.num_pets = (numPets === "") ? null : Number(numPets);

      need.behavioral_history = null;
      if (isCaPc290) {
        if (need.behavioral_history === null) {need.behavioral_history = []};
        if (isCaPc290 === "Yes") {need.behavioral_history.push("ca_pc_290");}
      }
      if (isArsonHistory) {
        if (need.behavioral_history === null) {need.behavioral_history = []};
        if (isArsonHistory === "Yes") {need.behavioral_history.push("arson");}
      }
      
      return need;
    }
    async function updateAndSearch() {
      // TO DO: Fix this
      if (!loading) {
        // only update to server if not the initial render
        if (!initialRender.current) {
          const need = getNeedObjectFromFormState();
          await updateNeedForId(needId, need); // TODO check for error
        }
        else {
          initialRender.current = false;
        }
        
        // validate that the form is complete
        if (referenceIdentifier.trim().length > 0 
          && (spaceTypeCategories && spaceTypeCategories.length > 0)
          && (age !== "") 
          && gender
          && isCaPc290
          && isArsonHistory
          && (numPets !== "")
          && currentRegion
        ) {
          setFormComplete(true);
        }
        else {
          setFormComplete(false);
        }

        const results = await searchSpaces(needId);
        setSearchResultsCount(results.matching_spaces);
        setSearchComplete(results.query_complete)
        
      }
    }
    updateAndSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, needId, referenceIdentifier, stringifiedSpaceTypeCategories, age, gender, currentRegion, isCaPc290, isArsonHistory, numPets, searchSpaces, updateNeedForId]);    

  const handleSpaceTypeCategoriesChange = (e) => {
    const value = e.target.value;
  
    if (e.target.checked) {
      // If the checkbox is checked, add the value to the array if it's not already there
      setSpaceTypeCategories((prevCategories) => [...prevCategories, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setSpaceTypeCategories((prevCategories) => prevCategories.filter((option) => option !== value));
    }
  };


  const handleAgeBlur = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && value >= 0 && value <= 120)) {
      setAge(value);
      setAgeError('');
    } else {
      //setAge('');
      setAgeError('Please enter a valid age (0-120).');
    }
  };

  const handleReferenceIdentifierBlur = (e) => {
    const newValue = e.target.value;
    if (newValue !== referenceIdentifier) {
      if (newValue && newValue.trim().length > 0) {
        setReferenceIdentifier(newValue.trim());
      }
      else {
        e.target.value = referenceIdentifier;
      }
    }
  }

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  }

  const handleBackClick = () => {
    navigate('/'); // Navigate back to the item list page
  };

  const handleViewSearchResults = () => {
    // validate for required fields
    navigate(`/needs/${id}/search-results`); // Navigate to the search results page
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-screen">
      
      <div className="flex-grow overflow-auto px-0 pt-4 pb-24"> 

          <div>
            <div className="pb-4">
              {/* Back Button */}
              <button onClick={handleBackClick} className="text-blue-600">
                ← Back
              </button>
            </div>

              
              {/* Reference Identifier */}
              <div className="mb-4 p-4 bg-gray-200 rounded-md">
                <label className="block text-lg font-semibold text-gray-900 mb-4" htmlFor="reference-identifier">
                  <p className="mb-2">Reference Identifier</p>
                  <p className="text-xs text-gray-500">The details of this search are saved under the following label. This is for your reference only.</p>
                </label>
                <input
                  id="reference-identifier"
                  type="text"
                  className="w-full px-4 py-3 border border-gray-300 rounded-md text-lg"
                  defaultValue={referenceIdentifier}
                  onBlur={handleReferenceIdentifierBlur}
                  required
                />
              </div>

              {/* Space Type Categories */}
              <div className="mb-8 p-4">
                <label className="block text-lg font-semibold text-gray-900 mb-4">
                  <p className="mb-2">What types of shelter spaces are appropriate for your client?</p>
                  <p className="text-sm text-gray-500">Choose all that apply</p>
                </label>
                <div className="mb-4">
                  <input
                    type="checkbox"
                    id="spacetypecategory-option-congregate"
                    value="congregate"
                    checked={spaceTypeCategories.includes('congregate')}
                    onChange={handleSpaceTypeCategoriesChange}
                  />
                  <label className="ml-4 text-lg" htmlFor="spacetypecategory-option-congregate">Congregate</label>
                </div>
                <div className="mb-4">
                  <input
                    type="checkbox"
                    id="spacetypecategory-option-noncongregate"
                    value="non_congregate"
                    checked={spaceTypeCategories.includes('non_congregate')}
                    onChange={handleSpaceTypeCategoriesChange}
                  />
                  <label className="ml-4 text-lg" htmlFor="spacetypecategory-option-noncongregate">Non-Congregate</label>
                </div>
                <div className="mb-4">
                  <input
                    type="checkbox"
                    id="spacetypecategory-option-safeparking"
                    value="safe_parking"
                    checked={spaceTypeCategories.includes('safe_parking')}
                    onChange={handleSpaceTypeCategoriesChange}
                  />
                  <label className="ml-4 text-lg" htmlFor="spacetypecategory-option-safeparking">Safe Parking Space</label>
                </div>
              </div>

              {/* Age */}
              <div className="mb-4 p-4">
                <label className="block text-lg font-semibold text-gray-900 mb-4" htmlFor="age">What is your client's age?</label>
                <input
                  id="age"
                  type="text"
                  className="w-full px-4 py-3 border border-gray-300 rounded-md text-lg"
                  defaultValue={age}
                  onBlur={handleAgeBlur}
                />
                {ageError && <p className="text-red-600 mt-2">{ageError}</p>}
              </div>

              {/* Gender */}
              <div className="mb-4 p-4">
                <label className="block text-lg font-semibold text-gray-900 mb-4">What is your client's gender?</label>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="gender-option-male"
                    name="gender"
                    value="male"
                    checked={gender === 'male'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-4 text-lg" htmlFor="gender-option-male">Male</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="gender-option-female"
                    name="gender"
                    value="female"
                    checked={gender === 'female'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-4 text-lg" htmlFor="gender-option-female">Female</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="gender-option-nonbinary"
                    name="gender"
                    value="nonbinary"
                    checked={gender === 'nonbinary'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-4 text-lg" htmlFor="gender-option-nonbinary">Non Binary</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="gender-option-transgendermale"
                    name="gender"
                    value="transgender_male"
                    checked={gender === 'transgender_male'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-4 text-lg" htmlFor="gender-option-transgendermale">Transgender Male</label>
                </div>
                <div >
                  <input
                    type="radio"
                    id="gender-option-transgenderfemale"
                    name="gender"
                    value="transgender_female"
                    checked={gender === 'transgender_female'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-4 text-lg" htmlFor="gender-option-transgenderfemale">Transgender Female</label>
                </div>
              </div>

              {/* PC 290 */}
              <div className="mb-4 p-4">
                <label className="block text-lg font-semibold text-gray-900 mb-4">Has your client been convicted of any offense that requires registration under California Penal Code 290?</label>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="pc290-option-yes"
                    name="pc290"
                    value="Yes"
                    checked={isCaPc290 === 'Yes'}
                    onChange={(e) => setIsCaPc290(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="pc290-option-yes">Yes</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="pc290-option-no"
                    name="pc290"
                    value="No"
                    checked={isCaPc290 === 'No'}
                    onChange={(e) => setIsCaPc290(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="pc290-option-no">No</label>
                </div>
              </div>

              {/* arson */}
              <div className="mb-4 p-4">
                <label className="block text-lg font-semibold text-gray-900 mb-4">Does your client have a history of committing arson?</label>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="arson-option-yes"
                    name="arson"
                    value="Yes"
                    checked={isArsonHistory === 'Yes'}
                    onChange={(e) => setIsArsonHistory(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="arson-option-yes">Yes</label>
                </div>
                <div className="">
                  <input
                    type="radio"
                    id="arson-option-no"
                    name="arson"
                    value="No"
                    checked={isArsonHistory === 'No'}
                    onChange={(e) => setIsArsonHistory(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="arson-option-no">No</label>
                </div>
              </div>

              {/* Pets */}
              <div className="mb-4 p-4">
                <label className="block text-lg font-semibold text-gray-900 mb-4">Does your client have a pet?</label>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="pets-option-none"
                    name="numPets"
                    value="0"
                    checked={numPets === '0'}
                    onChange={(e) => setNumPets(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="pets-option-none">No pets</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="pets-option-1"
                    name="numPets"
                    value="1"
                    checked={numPets === '1'}
                    onChange={(e) => setNumPets(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="pets-option-1">1 pet</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="pets-option-2"
                    name="numPets"
                    value="2"
                    checked={numPets === '2'}
                    onChange={(e) => setNumPets(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="pets-option-2">2 pets</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="pets-option-3"
                    name="numPets"
                    value="3"
                    checked={numPets === '3'}
                    onChange={(e) => setNumPets(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="pets-option-3">3 pets</label>
                </div>
              </div>

              {/* Current Region */}
              <div className="mb-4 p-4">
                <label className="block text-lg font-semibold text-gray-900 mb-4">Where is your client located?</label>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="currentregion-option-northcounty"
                    name="currentregion"
                    value="sdco_north_county"
                    checked={currentRegion === 'sdco_north_county'}
                    onChange={(e) => setCurrentRegion(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="currentregion-option-northcounty">North County</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="currentregion-option-centralsandiego"
                    name="currentregion"
                    value="sdco_central_sandiego"
                    checked={currentRegion === 'sdco_central_sandiego'}
                    onChange={(e) => setCurrentRegion(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="currentregion-option-centralsandiego">Central San Diego</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="currentregion-option-eastcounty"
                    name="currentregion"
                    value="sdco_east_county"
                    checked={currentRegion === 'sdco_east_county'}
                    onChange={(e) => setCurrentRegion(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="currentregion-option-eastcounty">East County</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="currentregion-option-southbay"
                    name="currentregion"
                    value="sdco_south_bay"
                    checked={currentRegion === 'sdco_south_bay'}
                    onChange={(e) => setCurrentRegion(e.target.value)}
                  />
                  <label className="ml-4 text-lg" htmlFor="currentregion-option-southbay">South Bay</label>
                </div>
              </div>

            
            
          </div>
        


        
      </div>

      {status && (
        <div className="bg-yellow-100 text-yellow-900 bg-opacity-95 p-6 text-center w-full fixed bottom-0 left-0 right-0">
          {status}
        </div>
      )}

      {!status && searchResultsCount === 0 && (
        <div className="bg-red-200 text-red-900 bg-opacity-95 p-6 text-center w-full fixed bottom-0 left-0 right-0">
          There are no matching spaces
        </div>
      )}

      {!status && searchResultsCount && !searchComplete && (
        <div className="bg-yellow-100 text-yellow-900 bg-opacity-95 p-6 text-center w-full fixed bottom-0 left-0 right-0">
          <p className="font-bold mb-2">{`We are searching ${searchResultsCount} possible spaces`}</p>
          <p className="text-xs">Tell us about your client and we'll keep narrowing things down</p>
        </div>
      )}

      {!status && searchResultsCount && searchComplete && (
        <div className={`${searchResultsCount ? 'bg-green-200 text-green-900' : 'bg-red-200 text-red-900'} bg-opacity-95 font-bold  p-6 text-center w-full fixed bottom-0 left-0 right-0`}>
          {`We found ${searchResultsCount} possible spaces`}

          {formComplete && (
            <div>
              <Button onClick={handleViewSearchResults} className="text-lg m-2 py-3 px-6">
                Review available spaces
              </Button>
            </div>
          )}
        </div>
      )} 
      


    </div>
  );
};

export default UpdateNeed;
